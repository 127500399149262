<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col lg="6">
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Search by ip"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-list-group>
      <b-list-group-item
        v-for="(ip, key) in items"
        :key="key"
      >
        <strong>{{ ip }}</strong>
      </b-list-group-item>
    </b-list-group>
    <b-button-toolbar class="mt-3 mx-auto">
      <b-button-group>
        <b-button
          v-if="show < ipList.length"
          variant="primary"
          @click="show+=25"
        >
          Show more
        </b-button>
        <b-button
          v-if="show > 25"
          variant="info"
          @click="show-=25"
        >
          Show less
        </b-button>
      </b-button-group>
    </b-button-toolbar>
  </div>
</template>
<script>
import {
  BInputGroup,
  BFormGroup,
  BFormInput,
  BButtonToolbar,
  BButtonGroup,
  BInputGroupAppend,
  BButton,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import ToolsApi from '@/api/tools'

export default {
  components: {
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BButtonToolbar,
    BButtonGroup,
  },
  data() {
    return {
      ipList: [],
      show: 25,
      filter: '',
    }
  },
  computed: {
    items() {
      return this.ipList.filter(item => item.includes(this.filter)).slice(0, this.show)
    },
  },
  beforeMount() {
    ToolsApi.getIpBlacklist()
      .then(response => {
        this.ipList = response
      })
  },
}
</script>
